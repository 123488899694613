import { NotificationsTypes, NotificationsState } from './actionsTypes';

export const INIT_STATE: NotificationsState = {
    events: [],
};

const NotificationsReducer = (state = INIT_STATE, action: any): NotificationsState => {
    switch (action.type) {
        case NotificationsTypes.ADD_TRAFFIC_ORDER_EVENT:
            return {
                ...state,
                events: [{
                    message: action.payload.message,
                    type: action.payload.type,
                    date: action.payload.date
                }, ...state.events],
            };
        case NotificationsTypes.READ_ALL_NOTIFICATIONS:
            return {
                events: []
            }
        default:
            return state;
    }
};

export default NotificationsReducer;