export enum NotificationsTypes {
    ADD_TRAFFIC_ORDER_EVENT = "@@notifications/ADD_TRAFFIC_ORDER_EVENT",
    READ_ALL_NOTIFICATIONS = "@@notifications/READ_ALL"
}


export interface Event {
    message: string;
    type: string;
    date?: Date;
    keys?: string[];
}

export interface NotificationsState {
    events: Event[];
}

