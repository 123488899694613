import {bool} from "yup";

export enum TrakerTypes {
    GET_TRAKER = "@@team/GET_TRAKER",
    GET_TRAKER_SUCCESS = "@@team/GET_TRAKER_SUCCESS",
    TRAKER_FAIL = "@@TRAKER_FAIL",
    MODAL_TRAKER = "@@MODAL_TRAKER",
    ADD_СAPA = "ADD_СAPA",
    ADD_CAPA_SUCCESS = "ADD_CAPA_SUCCESS",
    CAPA_FAIL = "CAPA_FAIL",
    GET_UPDATE_BROKER_NAME ="GET_UPDATE_BROKER_NAME",
    GET_UPDATE_BROKER_NAME_SUCCESS = "GET_UPDATE_BROKER_NAME_SUCCESS"

}

export interface TrakerState {
    data  :any;
    isOpenModal: boolean;
    error: object;
    loadingUpdateLoadingName: boolean;
    loading: boolean
}
