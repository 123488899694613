import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";
import FeatherIcon from "feather-icons-react";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../../../src/components/Common/withRouter";
import usePermissions from "src/hooks/usePermissions";

const SidebarContent = (props: any) => {
  const ref: any = useRef();

  const {hasPermission} = usePermissions();

  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items: any) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              {
                hasPermission("view-dashboard") &&
                <Link to="/dashboard" className="">
                  <FeatherIcon icon="home"/> <span>{props.t("Dashboard")}</span>
                </Link>
              }
            </li>
            <li>
              {
                hasPermission("view-brokers") &&
                <Link to="/brokers">
                  <FeatherIcon icon="briefcase"/> <span>{props.t("Brokers")}</span>
                </Link>
              }
            </li>
            <li>
              {
                hasPermission("view-teams") &&
                <Link to="/teams">
                  <FeatherIcon icon="users"/> <span>{props.t("Teams")}</span>
                </Link>
              }
            </li>
            <li>
              {
                hasPermission('view-spends') &&
                (
                  <Link to="/spends">
                    <FeatherIcon icon="grid"/> <span>{props.t("Spends")}</span>
                  </Link>
                )
              }
            </li>
            <li>
              {
                hasPermission('view-orders') &&
                (
                  <Link to="/orders">
                    <FeatherIcon icon="tool"/> <span>{props.t("Orders")}</span>
                  </Link>
                )
              }
            </li>
            <li>
              {
                hasPermission('view-users') &&
                (
                  <Link to="/users">
                    <FeatherIcon icon="user"/> <span>{props.t("Users")}</span>
                  </Link>
                )
              }
            </li>
            <li>
              {
                hasPermission('view-price-day') &&
                <Link to="/price-day">
                  <FeatherIcon icon="dollar-sign"/> <span>{props.t("Price day")}</span>
                </Link>
              }
            </li>
            {/*<li>
              {
                hasPermission('view-tracker') &&
                <Link to="/tracker-capa">
                  <FeatherIcon icon="activity"/> <span>{props.t("Tracker")}</span>
                </Link>
              }
            </li>*/}

            <li>
              {
                hasPermission('view-lead-logs') &&
                <Link to="/lead-dashboard">
                  <FeatherIcon icon="star"/> <span>{props.t("Lead Dashboard")}</span>
                </Link>
              }
            </li>

            {/* --- */}
            <li className="menu-title">{props.t("Traffic Management")}</li>
            <li>
              {
                hasPermission('view-traffic-orders') &&
                <Link to="/traffic-orders">
                  <FeatherIcon icon="grid"/> <span>{props.t("Traffic Orders")}</span>
                </Link>
              }
            </li>
            <li>
              {
                hasPermission('view-country-box') &&
                <Link to="/country-boxes">
                  <FeatherIcon icon="grid"/> <span>{props.t("Country Boxes")}</span>
                </Link>
              }
            </li>
            {/* --- */}

            <li className="menu-title">{props.t("Elements")}</li>
            <li>
              {
                hasPermission('view-checkin') &&
                <Link to="/check-in">
                  <FeatherIcon icon="calendar"/> <span>{props.t("Checkin")}</span>
                </Link>
              }
            </li>
            <li>
              {
                hasPermission('view-jira') &&
                <Link to="/jira-statistic">
                  <FeatherIcon icon="calendar"/> <span>{props.t("Jira Statistic")}</span>
                </Link>
              }
            </li>
            <li>
              {
                hasPermission('view-jira') &&
                <Link to="/dns-checker">
                  <FeatherIcon icon="calendar"/> <span>{props.t("DNS checker")}</span>
                </Link>
              }
            </li>
            <li>
              {
                hasPermission('view-jira') &&
                <Link to="/kio-domain-changer">
                  <FeatherIcon icon="calendar"/> <span>{props.t("Keitaro Domain Changer")}</span>
                </Link>
              }
            </li>
            <li className="menu-title">{props.t("Managements")}</li>
            <li>
              {
                hasPermission('view-roles') &&
                <Link to="/roles">
                  <FeatherIcon icon="settings"/> <span>{props.t("Roles")}</span>
                </Link>
              }
            </li>
            <li>
              {
                hasPermission('view-permissions') &&
                <Link to="/permissions">
                  <FeatherIcon icon="tool"/> <span>{props.t("Permissions")}</span>
                </Link>
              }
            </li>
            <li>
              {
                hasPermission('view-uni') &&
                <Link to="/uni">
                  <FeatherIcon icon="tool"/> <span>{props.t("Unicalizator")}</span>
                </Link>
              }
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
