
import {  call, put, takeEvery } from "redux-saga/effects";
import { TrakerTypes } from "./actionTypes";
import {
  getTrakerSuccess,
  modalTraker,
  addCapaFail,
  loadingUpdateLoadingName,
} from "./actions";
import { 
  getTrackerData as getTrackerDataApi,
  postCapa as postCapaApi,
  getUpdateBrokerName as getUpdateBrokerNameApi,
} from "../../helpers/backend_helper"


function* fetchTracker() {
    try {
    const response: Promise<any> = yield call(getTrackerDataApi);
    yield put(getTrakerSuccess(response));
    } catch (error) {
    console.log(error)
    }
  }

  function* addCapaSaga({data}: any) {
    try {
      const response: Promise<any> = yield call(postCapaApi, data);
      //yield put(addCapaSuccess(response));
      yield put(modalTraker(false))
    } catch (error) {
      yield put(addCapaFail(error));
    }
}

function* getUpdateBrokerName(){
  try{
    const response: Promise<any> = yield call(getUpdateBrokerNameApi);
    yield put(loadingUpdateLoadingName(false))
  } catch(error){
    console.log(error)
  }
}

function* statisticSaga() {
    yield takeEvery(TrakerTypes.GET_TRAKER, fetchTracker)
    yield takeEvery(TrakerTypes.ADD_СAPA, addCapaSaga)
    yield takeEvery(TrakerTypes.GET_UPDATE_BROKER_NAME,getUpdateBrokerName)
}  

export default statisticSaga;