import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import moment from "moment/moment";
import FeatherIcon from "feather-icons-react";
import { withTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {readAllNotifications} from "../../../store/notifications/actions";

const NotificationDropdown = (props: any) => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch()
  const {events} = useSelector((state: any) => state.Notifications);

  const handleReadAllNotifications = () => {
    dispatch(readAllNotifications())
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <FeatherIcon icon="bell" className="icon-lg" />
          {events.length > 0 &&
            <span className="badge bg-danger rounded-pill">{events.length}</span>
          }
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to="#" className="small"
                      onClick={handleReadAllNotifications}
                >
                  Read All
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>

            {events.length > 0 ? (
              events.map((event: { message: string; type: string; date: string }, index: number) => (
                <Link to="/traffic-orders" className="text-reset notification-item" key={index}>
                  <div className="d-flex">
                    <div className="avatar-sm me-3">
                    <span className={`avatar-title ${event.type === "update_traffic_order" ? "bg-primary" : "bg-success"} rounded-circle font-size-16`}>
                      <i className={`bx bx-badge-check`} />
                    </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">{event.message}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" /> { moment(event.date).fromNow()}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="text-center p-3">
                <p className="mb-0">{props.t("No new notifications")}</p>
              </div>
            )}

          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
              onClick={handleReadAllNotifications}
            >
              {props.t("Read all")}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
