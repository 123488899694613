import { NotificationsTypes, Event } from "./actionsTypes";

export const addTrafficOrderEvent = (event: Event) => ({
  type: NotificationsTypes.ADD_TRAFFIC_ORDER_EVENT,
  payload: {
    message: event.message,
    type: event.type,
    date: new Date(),
  },
});

export const readAllNotifications = () => ({
  type: NotificationsTypes.READ_ALL_NOTIFICATIONS,
})
