import React, {useEffect, useState} from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

// Redux Store
import { showRightSidebarAction } from "../../store/actions";

//import component
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import LightDark from "../CommonForBoth/Menus/LightDark";


//import images
import logoSvg from "../../assets/images/logo-sm.svg";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import {Link} from "react-router-dom";
import {onMessageListener, requestForToken} from "../../firebase";
import {UserService} from "../../services/user.service";
import {useMutation} from "@tanstack/react-query";

const Header = (props: any) => {
  const dispatch = useDispatch();
  const [authUser, setAuthUser] = useState<any>(null);

  const { mutate: setFcmToken, isPending, isSuccess, isError } = useMutation({
    mutationFn: (data: any) => UserService.setFcmToken(data),
    mutationKey: ["setFcmToken"],
    onError: (error: any) => {
      console.error("Error setting FCM token:", error);
    },
  });

  useEffect(() => {
    const checkAndRequestToken = async () => {
      if (Notification.permission === "default") {
        console.log("Permission not granted yet, requesting it...");
        try {
          const currentToken = await requestForToken();
          setFcmToken({ fcm_token: currentToken });
          console.log("Current token for client:", currentToken);
        } catch (error) {
          console.log("An error occurred while retrieving the token:", error);
        }
      }
    };
    checkAndRequestToken();
  }, []);

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        console.log("Message received. ", payload);
        // @ts-ignore
        alert(payload.notification?.title);
      })
      .catch((err) => console.log("failed: ", err));
  }, []);

  const nonauthData = createSelector(
    (state: any) => state.Layout,
    (layout) => ({
      layoutMode: localStorage.getItem("layoutMode") || layout.layoutMode,
      showRightSidebar: layout.showRightSidebar,
    })
  );

  const { layoutMode, showRightSidebar } = useSelector(nonauthData);

  const [isClick, setClick] = useState<boolean>(true);

  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick) {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  const authUserStorage = localStorage.getItem("authUser");

  useEffect(() => {
    if (authUserStorage != null) {
      setAuthUser(JSON.parse(authUserStorage));
      console.log(authUser?.roles[0].name)
    }
  }, [authUserStorage]);



  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{" "}
                  <span className="logo-txt">Company</span>
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{" "}
                  <span className="logo-txt">Company</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

          </div>

          <div className="d-flex  d-none d-sm-inline-block ">
            <h5>Welcome {authUser?.roles?.[0]?.name}</h5>
          </div>

          <div className="d-flex">
            <LanguageDropdown />

            {/* light / dark mode */}
            <LightDark
              layoutMode={layoutMode}
              onChangeLayoutMode={props.onChangeLayoutMode}
            />


            <NotificationDropdown />

            <div
              onClick={() => {
                dispatch(showRightSidebarAction(!showRightSidebar));
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
              >
                <FeatherIcon icon="settings" className="icon-lg" />
              </button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
