import React, {useEffect, useMemo, useState} from "react";
import {Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row} from "reactstrap";
import {useQuery} from "@tanstack/react-query";
import NoSearchResult from "../../../../components/Common/NoSearchResult";
import {TrafficOrderService} from "../../../../services/traffic_order.service";
import MultiDatePicker from "../../../../components/Common/MultiDatePicker";

interface IInfoOrder {
  isOpen: boolean,
  toggle: () => void,
  id: string
}

const InfoOrder = ({isOpen, toggle, id}: IInfoOrder) => {
  const [serverDates, setServerDates] = useState<Date[]>([]);

  const {
    data: trafficOrder,
    isLoading: isLoadingTrafficOrder,
    isError: isErrorTrafficOrder,
    isPending
  } = useQuery({
    queryKey: ["keyTrafficOrder", id],
    queryFn: () => TrafficOrderService.getTrafficOrder(+id),
    retry: false,
  });


  useEffect(() => {
    if(isErrorTrafficOrder) {
      toggle()
    }
  }, [isErrorTrafficOrder]);

  useEffect(() => {
    const parsedDates = trafficOrder?.capa_dates.map((dateString: any) => {
      const [year, month, day] = dateString.split("-").map(Number);
      return new Date(year, month - 1, day);
    });
    setServerDates(parsedDates);
  }, [trafficOrder?.capa_dates]);

  const createdUser = useMemo(
    () => trafficOrder?.users.find((i: any) => i.is_created === 1)?.name,
    [trafficOrder?.users]
  );

  return (
    <Offcanvas isOpen={isOpen && !isErrorTrafficOrder} direction="end" toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>
        Info Traffic Order {trafficOrder?.id}
      </OffcanvasHeader>


      {(!isPending) ? (
        <OffcanvasBody>

          {createdUser && <div className="mb-2">Who Created: {" "}
            <span className={`badge bg-info`}>
              {createdUser}
            </span>
          </div>}

          <div className="mb-2">
            <label>Date: {trafficOrder?.from_date} {trafficOrder?.from_date !== trafficOrder?.to_date ? '' : trafficOrder?.to_date} </label>
            <MultiDatePicker initialDates={serverDates} isDisabled={true}/>
          </div>

          <div className="mb-2">
            <label>Broker: {trafficOrder?.broker?.name}</label>
            <div>
              <span className={`badge ${trafficOrder?.broker_id ? 'bg-success' : 'bg-danger'}`}>
                <i
                  className={`bx ${trafficOrder?.broker_id ? 'bx-check-double' : 'bx-block'} font-size-16 align-middle me-2`}/>
                {trafficOrder?.broker_id ? 'Integrated' : 'Not Integrated'}
              </span>
            </div>
          </div>

          <div className="mb-2">
            <label>Buy method: {trafficOrder?.buy_method_label}</label>
          </div>

          {trafficOrder?.buy_method_id === 2 && (
            <div className="mb-2">
              <label>Resale: {trafficOrder?.resale}</label>
            </div>
          )}

          {trafficOrder?.buy_method_id === 1 && (
            <div className="mb-2">
              <label>Teams: {trafficOrder?.users
                .filter((i: any) => i.team_id > 0 && !i.is_responsible && i.role)
                .map((i: any) => i.name)
                .join(", ")}
              </label>
            </div>
          )}

          <div className="mb-2">
            <label>Responsible: {trafficOrder?.users
              .filter((i: any) => i.team_id > 0 && i.is_responsible)
              .map((i: any) => i.name)
              .join(", ")}</label>
          </div>

          <div className="mb-2">
            <label>Order GEO: {" "}
              {trafficOrder?.country_box.map((b: any) => b.label + " | ").join(", ")}
              {" "}
              {trafficOrder?.countries.map((i: any) => i.title + " | " + i.iso).join(", ")}
            </label>
          </div>

          <div className="mb-2">
            <label>Funnel name: {trafficOrder?.funnel_name}</label>
          </div>

          <div className="mb-2">
            <label>Pay method: {trafficOrder?.pay_method_label}</label>
          </div>

          <Row>
            <div className={`mb-2 ${trafficOrder?.pay_method_id === 3 ? "col-8" : ""}`}>
              <label>Payout: {trafficOrder?.payout}</label>
            </div>
            {trafficOrder?.pay_method_id === 3 && (
              <div className="mb-2 col-4">
                <label>CR {trafficOrder?.cr} %</label>
              </div>
            )}
          </Row>

          <div className="mb-2">
            <label>Daily Cap: {trafficOrder?.daily_cap}</label>
          </div>

          <Row>
            <div className="mb-2">
              <label>Call center time: {trafficOrder?.from_time} - {trafficOrder?.to_time}</label>
            </div>
          </Row>

        </OffcanvasBody>
      ) : (
        <NoSearchResult text="Loading ..."/>
      )}
    </Offcanvas>
  );
};

export default InfoOrder;